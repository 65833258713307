.row {
    color: white;
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
}

/* Create four equal columns that sits next to each other */
.column {
    color: white;
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
    max-width: 50%;
}

.header {
    align-content: center;
    justify-content: center;
    text-align: justify;
    padding-right: 3rem;
    padding-left: 3rem;
    font-size: 2rem;
}
.description {
    font-size: 1rem;
    align-content: center;
    justify-content: center;
    text-align: justify;
    padding-right: 3rem;
    padding-left: 3rem;
    line-height: 2rem;
    font-kerning: auto;
    -webkit-font-kerning: auto;
}
@media screen and (max-width: 800px) {
    .column {
        color: white;
        -ms-flex: 100%; /* IE10 */
        flex: 100%;
        max-width: 100%;
    }
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

    .column {
        color: white;
        -ms-flex: 100%; /* IE10 */
        flex: 100%;
        max-width: 100%;
    }

    .description {
        font-size: 0.8rem !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
    .header{
        padding-left: 2rem;
    }
}

@media screen and (max-width: 320px) {
    .column {
        color: white;
        -ms-flex: 100%; /* IE10 */
        flex: 100%;
        max-width: 100%;
    }

    .description {
        font-size: 0.6rem !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
    .header{
        padding-left: 2rem;
    }
}


