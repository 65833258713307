.gallery.row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
}

/* Create four equal columns that sits next to each other */
.gallery > .column {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
}

.gallery > .column img {
    vertical-align: middle;
    width: 100%;
}

.heading{
    text-align: center;
    font-size: 2rem;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .gallery > .column {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .gallery > .column {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
    }
    .gallery > .column:nth-child(2){
        display: none;
    }
    .gallery > .column:nth-child(3){
        display: none;
    }
    .gallery > .column:nth-child(4){
        display: none;
    }
    .gallery > .column:nth-child(1) > img:nth-last-child(-n+3){
        display: none;
    }
}
