.tag > .tag-button {
    padding: 0.3rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.5rem;
    font-weight: bold;
    margin: 0.225rem 0.225rem;
    background-color: white;
    border: 1px solid white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.tag > .tag-button:hover {
    background-color: lightgrey;
    border-radius: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid lightgrey;
}
@media screen and (max-width: 600px) {
    .tag:nth-last-child(-n+5){
        display: none;
    }
}

