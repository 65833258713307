.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.heading-container {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.body {
  height: 100vh;
  font-family: Verdana, serif;
  color: white;
  letter-spacing: 0.125rem;
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  -moz-transition: -moz-transform 200ms ease-in-out;
  -o-transition: -o-transform 200ms ease-in-out;
  transition: 0.2s ease-in-out;
  transform: translateZ(0) scale(1,1)!important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body > .center{
  left: 100px;
}
.body > .center > h1 {
  padding-right: 20rem;
  padding-bottom: 5rem;
}
