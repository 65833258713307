.timeline-link {
    font-family: Verdana;
    color: white;
    font-weight: bold;
    letter-spacing: 0.125rem;
    -webkit-transition: -webkit-transform 200ms ease-in-out;
    -moz-transition: -moz-transform 200ms ease-in-out;
    -o-transition: -o-transform 200ms ease-in-out;
    transition: 0.2s ease-in-out;
    transform: translateZ(0) scale(1,1)!important;
    font-size: 1rem;
}

.hr-line {
    transform: rotate(90deg);
    width: 40%;
    margin: 1rem;
}
.tags-container {
    float: left;
    display: flex;
    flex-wrap: wrap;
    padding-left: 40px;
}
.timeline-link.active{
    font-family: Verdana;
    color: white;
    letter-spacing: 0.225rem;
    padding-bottom: 0.125rem;
    border-bottom: 0.125rem solid currentColor;
    border-radius: 0.125rem;
    cursor: pointer;
    margin: 0;
    border-left: 0;
    border-right: 0;
}
.timeline-container {
    display: flex;
    height: 70vh;
}
.side_menu {
    right: 0;
    position: fixed;
    padding-right: 6rem;
    align-self: center;
}
.timeline-description > div > ul li {
    line-height: 1rem;
    margin-bottom: 1rem;
}
.timeline-description {
    flex-wrap: wrap;
    flex: 60%;
    max-width: 60%;
    display: flex;
    text-align: left;
    padding-left: 8rem;
}
.timeline-description > h1 {
    align-self: flex-end;
}
.timeline-link:hover {
    font-family: Verdana;
    color: white;
    padding-bottom: 0.125rem;
    border-bottom: 0.125rem solid currentColor;
    border-radius: 0.125rem;
    cursor: pointer;
    margin: 0;
    border-left: 0;
    border-right: 0;
}
@media screen and (max-width: 800px) {
    .timeline-link {
        font-size: 0.8rem;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .timeline-link {
        font-size: 0.8rem;
    }
    .timeline {
        float: left;
        padding-bottom: 0;
        padding-right: 1rem;
    }
    .hr-line {
        display: none;
    }
    .timeline:last-child {
        padding: 0;
    }
    .timeline-container {
        display: block;
    }
    .side_menu {
        position: relative;
        padding-top: 0;
        justify-content: center;
        display: flex;
        padding-right: 0;
    }

    .timeline-description {
        text-align: left;
        flex: 80%;
        max-width: 80%;
        text-align: left;
        margin-top: 2rem;
        padding-left: 2rem;
    }
    .timeline-description > h1 {
        font-size: 1rem;
    }
    .timeline-description > div > ul {
        font-size: 0.8rem;
        padding-left: 1rem;
    }
    .tags-container {
        padding-left: 1rem;
    }

}

@media screen and (max-width: 360px) {
    .timeline-link {
        font-size: 0.6rem;
        padding-right: 0.5rem;
    }
    .timeline-description > h1 {
        font-size: 0.6rem;
    }

    .timeline-description > div > ul {
        font-size: 0.4rem;
        padding-left: 1rem;
    }

    .timeline-description > div > ul li:nth-child(2){
        display: none;
    }
    .tags-container {
        padding-left: 0.5rem;
    }
}
