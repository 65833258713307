.social.row {
    color: white;
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
}

/* Create four equal columns that sits next to each other */
.social > .column {
    color: white;
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
    max-width: 50%;
    display: block;
    padding-bottom: 4rem;
}

h1.header {
    align-content: center;
    justify-content: center;
    font-size: 2rem;
    text-align: center;
    padding-right: 6rem;
    font-size: 2rem;
    padding-bottom: 3rem;
}

.description {
    font-size: 1rem;
    align-content: center;
    justify-content: center;
    text-align: justify;
    padding-right: 6rem;
    line-height: 2rem;
}

i {
    font-size: 5rem;
    margin: 3rem;
    color: white;
    -webkit-transition: -webkit-transform 200ms ease-in-out;
    -moz-transition: -moz-transform 200ms ease-in-out;
    -o-transition: -o-transform 200ms ease-in-out;
    transition: 0.2s ease-in-out;
    transform: translateZ(0) scale(1,1)!important;
}
a {
    text-decoration: none;
    text-underline: none;
}
i:hover {
    font-size: 6rem;
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .social > .column {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
        display: block;
    }
    i {
        font-size: 3rem;
        margin: 2rem;
        color: white;
        -webkit-transition: -webkit-transform 200ms ease-in-out;
        -moz-transition: -moz-transform 200ms ease-in-out;
        -o-transition: -o-transform 200ms ease-in-out;
        transition: 0.2s ease-in-out;
        transform: translateZ(0) scale(1,1)!important;
    }
    a {
        text-decoration: none;
        text-underline: none;
    }
    i:hover {
        font-size: 4rem;
    }
    h1 {
        font-size: 1.5rem;
    }
}
