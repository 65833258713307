.heading {
    color: white;
}
.heading.center {
    color: white;
    font-size: 10rem;
    align-self: center;
    letter-spacing: 2.225rem;
    background: url(../../images/g.gif) no-repeat;
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10rem;
}
.heading.center > p {
    margin-bottom: 0;
}
.heading.center > .caption {
    font-size: 1rem;
    color: white;
    letter-spacing: 1.225rem;
}
.hide{
    display: none;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .heading.center {
        font-size: 5rem;
    }
    .heading.center > .caption {
        font-size: 0.7rem;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .heading.center {
        font-size: 4rem;
        letter-spacing: 1.225rem;
        padding-left: 1.125rem;
    }
    .heading.center > .caption {
        font-size: 0.2rem;
        letter-spacing: 0.225rem;

    }
}
