.navigation-link {
    font-family: Verdana;
    color: white;
    font-weight: bold;
    letter-spacing: 0.125rem;
    -webkit-transition: -webkit-transform 200ms ease-in-out;
    -moz-transition: -moz-transform 200ms ease-in-out;
    -o-transition: -o-transform 200ms ease-in-out;
    transition: 0.2s ease-in-out;
    transform: translateZ(0) scale(1,1)!important;
    font-size: 1rem;
}
.navigation-group{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.navigation-group > div {
    float: left;
    padding: 1rem;
}
.navigation-group-xs > div > div > div{
    padding-bottom: 0.5rem;
}
.navigation-group-xs {
    position: absolute;
}
.navigation-group-xs > div > div {
    padding-top: 5rem;
    padding-left: 2.5rem;
    text-align: left;
}
.icon-menu {
    font-size: 1.5rem;
    padding-left: 0.5rem;
    left: 0;
    position: fixed;
}
.icon-menu:hover {
    font-weight: bold;
    cursor: pointer;
    font-size: 1.5rem;
}

.navigation-link:hover {
    font-family: Verdana;
    color: white;
    font-weight: bold;
    letter-spacing: 0.225rem;
    padding-bottom: 0.125rem;
    border-bottom: 0.125rem solid currentColor;
    border-radius: 0.125rem;
    cursor: pointer;
    margin: 0;
    border-left: 0;
    border-right: 0;
}

@media screen and (max-width: 800px) {
    .navigation-link {
        font-size: 0.8rem;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .navigation-link {
        font-size: 0.7rem;
    }
}

